export enum RoleString {
    ADMIN = 'admin',
    USER = 'user',
    MASTER = 'master',
    DEV = 'dev',
    MANAGER = 'manager',
    DRIVER = 'driver',
    WAREHOUSEMAN = 'warehouseman',
    WORKER = 'worker',
}
