import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { listNameWorkApi, scopeWorkApi } from '../../api';
import {
    INameListWork,
    IObjectCreateResponse,
    IScopeWorkSlice,
    ITypeWork,
    IUserWithDescriptionDto,
} from '../../interfaces';
import FilteringOptionsHome from './FilteringOptionsHome';
import GetOneByTypeWorkId from './GetOneByTypeWorkId';
import GetOneScopeWorkById from './GetOneScopeWorkById';

const initialState: IScopeWorkSlice = {
    selectedTypeWorkId: '',
    nameWorksSelected: [],
    filteringOptions: {
        home: {
            onlyCompleted: false,
            onlyNotCompleted: false,
            objectName: '',
            typeWorkName: '',
            isDel: false,
        },
    },
    scopeWorkData: {
        listNameWork: [],
        namesWorkGeneral: [],
        object: null,
        typeWork: null,
        users: [],
    },
    selectedScopeWorkById: {
        id: null,
        deletedAt: null,
        typeWork: null,
        object: null,
        createdAt: '',
        updatedAt: '',
        listNameWork: [],
        namesWorkGeneral: [],
        users: [],
        usersIds: [],
        addingCount: null,
        idScopeWork: [],
        isDifference: false,
        listNameWorkId: [],
        percent: '',
        quantityDifference: null,
        quntity: null,
    },
    // selectedScopeWorkById: null,
    isError: false,
    isLoading: false,
    dataError: null,
};

export const scopeWorkSlice = createSlice({
    name: 'scopeWork',
    initialState,
    reducers: {
        selectedTypeWorkIdInScopeWork: (state, action) => {
            state.selectedTypeWorkId = action.payload;
        },
        addTypeWork: (
            state,
            action: PayloadAction<{
                listTypeWork: ITypeWork[];
                typeWorkId: string;
            }>
        ) => {
            const { listTypeWork, typeWorkId } = action.payload;
            const findedTypeWork = listTypeWork.find(
                (item) => item.id === Number(typeWorkId)
            );
            if (findedTypeWork) {
                state.scopeWorkData.typeWork = findedTypeWork;
            }
        },
        addObject: (
            state,
            action: PayloadAction<{
                listObject: IObjectCreateResponse[];
                objectId: string;
            }>
        ) => {
            const { listObject, objectId } = action.payload;
            const findedObject = listObject.find(
                (item) => item.id === Number(objectId)
            );
            if (findedObject) {
                state.scopeWorkData.object = findedObject;
            }
        },
        addUsers: (
            state,
            action: PayloadAction<{
                listUser: IUserWithDescriptionDto[];
                listSelected: string[];
            }>
        ) => {
            state.scopeWorkData.users = [];
            const { listSelected, listUser } = action.payload;

            const arr: IUserWithDescriptionDto[] = [];
            for (let i = 0; i < listSelected.length; i++) {
                const findedUser = listUser.find(
                    (item) => item.id === Number(listSelected[i])
                );
                if (findedUser) {
                    arr.push(findedUser);
                }
            }
            state.scopeWorkData.users = arr;
        },

        addList: (state, action: PayloadAction<{ arrListId: React.Key[] }>) => {
            const { arrListId } = action.payload;
            const mainList = current(state.nameWorksSelected);

            const arr: INameListWork[] = [];
            // let listNames: INameWorkWithNameList[] = [];
            for (const listId of arrListId) {
                const findedList = mainList.find(
                    ({ id }) => id === Number(listId)
                );
                if (findedList) {
                    arr.push(findedList);
                    //listNames = [...findedList.nameWorks];
                }
            }

            state.scopeWorkData.listNameWork = arr;
        },
        resetScopeWorkData: (state) => {
            state.scopeWorkData.listNameWork = [];
            state.scopeWorkData.namesWorkGeneral = [];
            state.scopeWorkData.object = null;
            state.scopeWorkData.typeWork = null;
            state.scopeWorkData.users = [];
            state.selectedTypeWorkId = '';
            state.nameWorksSelected = [];
        },

        delList: (
            state,
            action: PayloadAction<{ id: number; idScopeWork: number }>
        ) => {
            console.log(action.payload);

            state.selectedScopeWorkById.listNameWork =
                state.selectedScopeWorkById?.listNameWork.filter(
                    (item) => item.id !== action.payload.id
                );
        },

        delForCreate: (state, action) => {
            const id = action.payload;
            state.scopeWorkData.listNameWork =
                state.scopeWorkData.listNameWork.filter(
                    (item) => item.id !== id
                );
        },
        delForEdit: (state, action) => {
            if (state.selectedScopeWorkById !== null) {
                state.selectedScopeWorkById.listNameWork =
                    state.selectedScopeWorkById?.listNameWork.filter(
                        (item) => item.id !== action.payload
                    );
            }
        },

        addNameListForEdit: (
            state,
            action: PayloadAction<{ arrListId: React.Key[] }>
        ) => {
            const { arrListId } = action.payload;
            const listForPush = [];
            for (const itemKey of arrListId) {
                const findedList = current(state.nameWorksSelected).find(
                    (item) => item.id === itemKey
                );
                if (findedList) {
                    listForPush.push(findedList);
                }
            }
            if (state.selectedScopeWorkById !== null) {
                state.selectedScopeWorkById.listNameWork = [
                    ...state.selectedScopeWorkById.listNameWork,
                    ...listForPush,
                ];
            }
        },
        editUsers: (
            state,
            action: PayloadAction<{
                listUser: IUserWithDescriptionDto[];
                listSelected: string[];
            }>
        ) => {
            const { listSelected, listUser } = action.payload;

            const arr: IUserWithDescriptionDto[] = [];
            for (let i = 0; i < listSelected.length; i++) {
                const findedUser = listUser.find(
                    (item) => item.id === Number(listSelected[i])
                );
                if (findedUser) {
                    arr.push(findedUser);
                }
            }
            state.scopeWorkData.users = [];

            if (state.selectedScopeWorkById !== null) {
                state.selectedScopeWorkById.users = arr;
            }
        },

        setObjectName: FilteringOptionsHome.setObjectName,
        setTypeWorkName: FilteringOptionsHome.setTypeWorkName,
        setOnlyCompleted: FilteringOptionsHome.setOnlyCompleted,
        setOnlyNotCompleted: FilteringOptionsHome.setOnlyNotCompleted,
        setCandidateForDel: FilteringOptionsHome.setCandidateForDel,
        resetFilteringOptions: FilteringOptionsHome.reset,
    },
    extraReducers(builder) {
        // Получаем списки по id
        builder.addMatcher(
            listNameWorkApi.endpoints.getOneByTypeWorkId.matchPending,
            GetOneByTypeWorkId.pending
        );
        builder.addMatcher(
            listNameWorkApi.endpoints.getOneByTypeWorkId.matchFulfilled,
            GetOneByTypeWorkId.fulfilled
        );
        builder.addMatcher(
            listNameWorkApi.endpoints.getOneByTypeWorkId.matchRejected,
            GetOneByTypeWorkId.rejected
        );

        // Получаем объём по id
        builder.addMatcher(
            scopeWorkApi.endpoints.getOneByIdScopeWork.matchPending,
            GetOneScopeWorkById.pending
        );
        builder.addMatcher(
            scopeWorkApi.endpoints.getOneByIdScopeWork.matchFulfilled,
            GetOneScopeWorkById.fulfilled
        );
        builder.addMatcher(
            scopeWorkApi.endpoints.getOneByIdScopeWork.matchRejected,
            GetOneScopeWorkById.rejected
        );
    },
});

export const {
    selectedTypeWorkIdInScopeWork,
    addTypeWork,
    addObject,
    addUsers,
    addList,
    resetScopeWorkData,
    delForCreate,
    delForEdit,
    addNameListForEdit,
    editUsers,
    delList,
    setObjectName,
    setOnlyCompleted,
    setOnlyNotCompleted,
    setTypeWorkName,
    resetFilteringOptions,
    setCandidateForDel,
} = scopeWorkSlice.actions;
export const scopeWorkReducer = scopeWorkSlice.reducer;
